<template>
  <tbody class="px-table-body px-table-row-hover px-table-row-highlight">
    <tr
      v-for="(item, index) in filteredDataSliced"
      :key="index"
      class="px-table-body-tr"
    >
      <td
        v-if="showSelect && isAnyItemEnabled"
        rowspan="1"
        colspan="1"
        style="text-align: center"
        :class="`px-table-body-td ${checkDisabling(item)}`"
      >
        <label
          v-if="multipleSelect || (!selectedItems.length || checkboxes[index])"
          class="px-checkbox px-table-checkbox-wrapper"
          style="display: inline-block"
        >
          <span :class="`px-checkbox-content ${checkboxes[index] ? 'px-checkbox-checked' : ''}`">
            <input
              v-model="checkboxes[index]"
              type="checkbox"
              class="px-checkbox-input"
              value
              @change="onItemSelected(item, index)"
            >
            <span class="px-checkbox-inner" />
          </span>
          <span class="px-checkbox-label" />
        </label>
      </td>
      <template v-for="([key, value]) in Object.entries(item)">
        <td
          v-if="!hiddenCols.includes(key)"
          :key="key"
          rowspan="1"
          colspan="1"
          :class="`px-table-body-td ${checkDisabling(item)}`"
        >
          <span v-if="slots.includes(key)">
            <Component
              :is="value.type"
              :key="index"
              v-bind="value.props"
              @change="value.handleChange"
            >
              {{ value.props && value.children || '' }}
            </Component>
          </span>
          <span v-else-if="key === 'reservation_start_date' || key === 'reservation_expiration_date'">
            {{ dateFormatted(value) }}
          </span>
          <span v-else class="px-table-body-td-span d-flex">{{ value }}</span>
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script>
import { VSelect, VCheckbox, VBtn } from 'vuetify/lib'

export default {
  name: 'CMTableBody',

  components: {
    VSelect,
    VCheckbox,
    VBtn,
    CMBtnIcon: () => import('@/components/Common/CMBtnIcon.vue')
  },

  props: {
    showSelect: {
      type: Boolean,
      default: false
    },

    slots: {
      type: Array,
      default: () => ([])
    },

    pageSize: {
      type: Number,
      default: 10
    },

    pageIndex: {
      type: Number,
      default: 1
    },

    checkboxesParent: {
      type: Array,
      default: () => ([])
    },

    filteredData: {
      type: Array,
      default: () => ([])
    },

    multipleSelect: {
      type: Boolean,
      default: true
    },

    selectedItems: {
      type: Array,
      default: () => ([])
    },

    hiddenCols: {
      type: Array,
      default: () => ([])
    },

    isAnyItemEnabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      checkboxes: []
    }
  },

  computed: {
    filteredDataSliced () {
      const { pageIndex, pageSize } = this
      return this.filteredData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
    }
  },

  watch: {
    checkboxesParent () {
      this.checkboxes = [...this.checkboxesParent]
    }
  },

  methods: {
    onItemSelected (item, index) {
      this.$emit('onItemSelected', {
        index,
        item,
        checkboxes: this.checkboxes
      })
    },

    dateFormatted (date) {
      const dateData = new Date(date)
      const options = {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      return dateData.toLocaleDateString('en-UK', options)
    },

    checkDisabling (item) {
      return item.isDisabled ? 'px-table-body-td-disabled' : ''
    }
  }
}
</script>

<style scoped>
.px-table-body-td-disabled {
  background-color: #e0e0e080;
}

.px-table-body-tr:hover td:not(.px-table-body-td-disabled) {
  background-color: #121212 !important;
}

.px-table-body-tr {
  height: 40px;
}

.px-table-body-td {
  white-space: pre-wrap;
  background-color: #141414;
  color: #ffffffd9;
  height: inherit;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  border-bottom: 1px solid #000;
}
.px-table-body-td-span {
  height: 100%;
  place-content: center;
  place-items: center;
}
.px-checkbox {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.px-table-checkbox-wrapper {
  width: 25px;
  display: inline-block;
}

.px-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.px-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #abbacc;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.px-checkbox-filter {
  display: inline-block;
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner {
  background-color: #f4c020;
  border-color: #f4c020;
}

.px-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4px;
  top: 2px;
  display: table;
  width: 6px;
  height: 9px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6)
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.px-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #f4c020;
  content: "";
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.px-checkbox-content {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: text-bottom;
}

.px-checkbox-label {
  margin: 0 6px 0 3px;
  width: 100%;
  color: #ffffffd9 !important;
}

.px-table-body-td-disabled > .px-checkbox {
  display: none !important;
}

::v-deep tbody > tr:hover {
  cursor: pointer !important;
  background-color: #896f1f !important;
}

::v-deep th,
::v-deep td {
  font-size: 10px !important;
  padding: 0px 4px !important;
}

@media screen and (max-width: 1600px) {
  ::v-deep th > span {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
}
</style>
